<template>
    <div>
      <b-row>
        <b-col md="12">
          <b-card>
            <b-overlay :show="loadingData" variant="transparent" no-wrap />
            <b-modal id="modalResponse" title="PopUp Response" cancel-variant="outline-secondary" scrollable>
              <b-card-text>
                {{ rows }}
              </b-card-text>
            </b-modal>
            <!-- search -->
            <h1>{{ this.dataItems.user.userName }}</h1>
            <br>
            <b-col md="3" class="d-flex align-items-center justify-content-center mb-1">
              <b-form-input v-model="search" class="d-inline-block mr-1" placeholder="Filtrar" />
            </b-col>
            <!-- table -->
            <br>
            <span class="alert alert-danger">{{ $t('user-list.text.lastConexion') }} {{ this.dataItems.user.lastLogin | moment }}</span>
            <br>
            <br>
            <b-table md="9" :items="dataItems.bitacora" :fields="fields" class="scroll" responsive>
              <template #cell(dateNow)="data">
                {{ data.item.dateNow | moment }}
              </template>
            </b-table>
            <div class="mx-2 mb-2">
              <b-row>
                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                  <span class="text-muted">{{ $t('labels.showing') }} {{ "-" }} {{ $t('labels.to') }} {{
                      "-"
                  }} {{ $t('labels.of') }} {{ "-" }} {{ $t('labels.entries') }}</span>
                </b-col>
                <!-- Pagination -->
                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
  
                  <b-pagination v-model="serverParams.page" :total-rows="totalRows" :per-page="serverParams.limit"
                    first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item"
                    :simple="true" :hide-goto-end-buttons="true">
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
  
                </b-col>
  
              </b-row>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </template>
  <script>
  import {
    BCard,
    BRow,
    BCol,
    BTable,
    BOverlay,
    BPagination,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BInputGroup,
    BCollapse,
    VBToggle,
    VBModal,
    BCardText,
  } from "bootstrap-vue";
  import vSelect from "vue-select";
  import flatPickr from "vue-flatpickr-component";
  import { VueGoodTable } from "vue-good-table";
  import store from "@/store";
  import axios from "@axios";
  import Ripple from "vue-ripple-directive";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  import moment from "moment";
  import HierarchyUsers from "@core/components/HierarchyUsers/HierarchyUsers.vue";
  
  export default {
    components: {
      BCard,
      BRow,
      BCol,
      BTable,
      BOverlay,
      BPagination,
      BFormSelect,
      BFormGroup,
      BFormInput,
      BInputGroupAppend,
      BButton,
      BInputGroup,
      BCollapse,
      VBModal,
      BCardText,
      VBToggle,
      vSelect,
      VueGoodTable,
      flatPickr,
      ToastificationContent,
      HierarchyUsers
    },
    directives: {
      "b-toggle": VBToggle,
      "b-modal": VBModal,
      Ripple,
    },
    data() {
      return {
        fields: [
          { label: "Fecha", key: "dateNow" },
          { label: "IP", key: "ip" },
        ],
        loadingData: false,
        dataItems: [],
        dataIdUser: [],
        pages: [20, 40, 60],
        search: "",
        rows: [],
        serverParams: {
          page: 1,
          limit: 20,
        },
        totalRows: 0,
        filterSelect: {
          ticketId: "",
          status: "",
          type: "",
          dateFrom: moment().subtract(7, "days").startOf("day").format("YYYY-MM-DD HH:mm:ss"),
          dateTo: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
        },
        searchId: "",
  
      };
    },
    // add filters
    filters: {
      moment: function (date) {
        return moment(date).format("MM/DD/YYYY HH:mm:ss");
      },
    },
  
    methods: {
      /*List - Index 123*/
      async showBitacora() {
        try {
          this.loadingData = true;
          const VUE_APP_URL = process.env.VUE_APP_URL
  
          const params = {
            "id": this.$route.params.id
          }
  
          const result = axios
            .post(`${VUE_APP_URL}/showBitacora`, params)
            .then(r => {
              this.dataItems = r.data
            })
            .catch(error => console.log('Error Encontrado:', error.response))
  
          return result
  
        } catch (error) {
          console.log(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('user-list.messages.errorLoadData'),
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        } finally {
          this.loadingData = false;
        }
      },
      async selectUserHierarchy(id) {
        const currency = this.$store.state.whitelabelCurrencyNabvar.currency
        const whitelabel = 248
        const VUE_APP_URL = process.env.VUE_APP_URL
        const params = {
          "id": id,
          "currency": currency,
          "whitelabel": whitelabel
        }
        let result = await axios
          .post(`${VUE_APP_URL}/treeUsers`, params)
          .then(r => r.data == "" ? 0 : r.data)
          .catch(error => console.log(error.response))
        return result
  
      },
      onPageChange(page) {
        console.log(page, "page");
        this.serverParams.page = page;
      },
      onPerPageChange(limit) {
        this.serverParams.page = 1;
        this.serverParams.limit = limit;
      },
      inputSelect() {
        let id = this.dataIdUser
      },
    },
    mounted() {
        this.showBitacora()
    },
    destroyed() {
      if (store.hasModule("reports")) store.unregisterModule("reports");
    },
  };
  </script>
  
  <style lang="scss" >
  .per-page-selector {
    width: 90px;
  }
  
  .scroll::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  
  .scroll::-webkit-scrollbar-button:increment,
  .scroll::-webkit-scrollbar-button {
    display: none;
  }
  
  .scroll::-webkit-scrollbar:horizontal {
    height: 10px;
  }
  
  .scroll::-webkit-scrollbar-thumb {
    background-color: #797979;
    border-radius: 20px;
  }
  
  .scroll::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  
  @import "@core/scss/vue/libs/vue-select.scss";
  @import "@core/scss/vue/libs/vue-good-table.scss";
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
  </style>
  
  